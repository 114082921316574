import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { drop, star, s } from "../../utils/constants";
import "./repos.scss";
// import { set_data_star } from "../../redux/generalDataSlice";
// import dayjs from "dayjs";

function Repos() {
  const { datas } = useSelector((state) => state.generalData);
  // const dispatch = useDispatch()
  const starred = (a) => {
    let child = a.target.parentNode.childNodes;
    for (let i = 0; i < child.length; i++) {
      if (!a.target.matches(".drop")) {
        if (child[i].innerText === "Star") {
          if (child[i].tagName === "P") {
            child[0].src = s;
            child[1].innerText = "Starred";
          } else {
            child[0].src = s;
            child[1].childNodes[1].innerText = "Starred";
          }
          // console.log(child[i].tagName);
        } else if (child[i].innerText === "Starred")
          if (child[i].tagName === "P") {
            child[0].src = star;
            child[1].innerText = "Star";
          } else {
            child[0].src = star;
            child[1].childNodes[1].innerText = "Star";
          }
      }
    }
    if (a.target.matches(".drop")) {
      console.log(a.target);
    }
    // dispatch(set_data_star(data_stars+1))
    // console.log(dispatch);
    // console.log(data_stars)
  };

  return (
    <div className="repos">
      <form action="echo.htmlacademy.com" method="GET" className="repos__form">
        <div className="input__wrapper">
          <div className="input">
            <input
              type="search"
              className="form__search"
              placeholder="Find a repository..."
            />
          </div>
          <div className="option__wrapper">
            <div className="form__drop">
              <select className="form__select" name="type" id="">
                <option defaultValue={"Type"} hidden>
                  Type
                </option>
                <option value="all">All</option>
                <option value="public">Public</option>
                <option value="private">Private</option>
                <option value="sources">Sources</option>
                <option value="forks">Forks</option>
                <option value="archived">Archived</option>
                <option value="mirrors">Mirrors</option>
                <option value="templates">Templates</option>
              </select>
              <img src={drop} className="drop" alt="no star" />
            </div>
            <div className="form__drop">
              <select className="form__select" name="languages" id="">
                <option defaultValue={"Languages"} hidden>
                  Languages
                </option>
                <option value="all">All</option>
                <option value="javascript">Javascript</option>
                <option value="scss">SCSS</option>
                <option value="html">Html</option>
                <option value="css">CSS</option>
                <option value="vue">Vue</option>
                <option value="python">Python</option>
              </select>
              <img src={drop} className="drop" alt="no star" />
            </div>
            <div className="form__drop">
              <select className="form__select" name="sort" id="">
                <option defaultValue={"Sort"} hidden>
                  Sort
                </option>
                <option value="last updated">Last updated</option>
                <option value="name">Name</option>
                <option value="stars">Stars</option>
              </select>
              <img src={drop} className="drop" alt="no star" />
            </div>
            <div className="new__wrapper">
              <svg
                aria-hidden="true"
                height="16"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                data-view-component="true"
                className="octicon octicon-repo"
              >
                <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
              </svg>
              <Link className="repos__form-link">New</Link>
            </div>
          </div>
        </div>
      </form>
      <ul className="repos__wrapper">
        {datas.map((data) => (
          <li key={uuid()} onClick={starred}>
            <div className="name flex">
              <div className="name__wrapper">
                <h4>{data.name}</h4>
                <span>{data.visibility}</span>
              </div>
              <div className="star__wrapper flex">
                <img src={star} alt="starred" />
                <p className="starr">Star</p>
                <img src={drop} className="drop" alt="no star" />
              </div>
            </div>
            <div className="lang__wrapper">
              <div className="lang">
                <span></span>
                {data.language && <p>{data.language}</p>}
              </div>
              <time>
                Updated on{" "}
                {new Date(data.updated_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </time>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Repos;
