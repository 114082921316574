import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import uuid from "react-uuid";
// import { data } from "../../redux/generalDataSlice";
import { repo } from "../../utils/constants";
import "./singlePage.scss";

function SinglePage() {
  // <NavLink to={"Salom"}/>
  // console.log(window.location.href);
  const { datas } = useSelector((state) => state.generalData);
  // const dispatch = useDispatch();
  // const { name } = useParams();
  // <Navigate to={"/salom"} />;
  // console.log("dispatch",dispatch)

  // useEffect(() => {
  //   axios
  //     .get(`/Tohirjon-Odilov/repos`)
  //     .then((response) => {
  //       dispatch(data(response.data));
  //     })
  //     .catch((err) => {
  //       throw Error(err);
  //     });
  // }, [name, dispatch]);

  // console.log("single",name);
  console.log(datas)

  return (
    <div className="single">
      <div className="title__wrapper flex">
        <h3>Pinned</h3>
        <p>Customize your pins</p>
      </div>
      <ul>
        {datas &&
          datas.slice(0, 10).map((data) => {
            return (
              <li key={uuid()}>
                <div className="repo flex">
                  <div className="flex">
                    <div className="flex">
                      <img src={repo} alt="Repository" />
                      <NavLink>{data.name}</NavLink>
                    </div>
                    <span>{data.visibility}</span>
                  </div>
                  <svg
                    aria-hidden="true"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                    width="16"
                    data-view-component="true"
                    className="octicon octicon-grabber"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 13a1 1 0 100-2 1 1 0 000 2zm-4 0a1 1 0 100-2 1 1 0 000 2zm1-5a1 1 0 11-2 0 1 1 0 012 0zm3 1a1 1 0 100-2 1 1 0 000 2zm1-5a1 1 0 11-2 0 1 1 0 012 0zM6 5a1 1 0 100-2 1 1 0 000 2z"
                    ></path>
                  </svg>
                </div>
                <p className="description">{data?.description}</p>
                <div className="column2">
                  <div className="flex">
                    {data.language && <span className="sircle"></span>}
                    <p>{data.language}</p>
                  </div>
                  {data.language && (
                    <div className="flex">
                      <svg
                        aria-label="star"
                        role="img"
                        height="16"
                        viewBox="0 0 16 16"
                        version="1.1"
                        width="16"
                        data-view-component="true"
                        className="octicon octicon-star"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                        ></path>
                      </svg>
                      <p>{1}</p>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        {/* <li>
          <div className="repo flex">
            <div className="flex">
              <img src={repo} alt="Repository" />
              <p>exam-month5-redux</p>
              <span>{"Public"}</span>
            </div>
            <svg
              aria-hidden="true"
              height="16"
              viewBox="0 0 16 16"
              version="1.1"
              width="16"
              data-view-component="true"
              className="octicon octicon-grabber"
            >
              <path
                fillRule="evenodd"
                d="M10 13a1 1 0 100-2 1 1 0 000 2zm-4 0a1 1 0 100-2 1 1 0 000 2zm1-5a1 1 0 11-2 0 1 1 0 012 0zm3 1a1 1 0 100-2 1 1 0 000 2zm1-5a1 1 0 11-2 0 1 1 0 012 0zM6 5a1 1 0 100-2 1 1 0 000 2z"
              ></path>
            </svg>
          </div>
          <div className="column2">
            <div className="flex">
              <span className="sircle"></span>
              <p>{"Javascript"}</p>
            </div>
            <div className="flex">
              <svg
                aria-label="star"
                role="img"
                height="16"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                data-view-component="true"
                className="octicon octicon-star"
              >
                <path
                  fillRule="evenodd"
                  d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                ></path>
              </svg>
              <p>{1}</p>
            </div>
          </div>
        </li> */}
      </ul>
    </div>
  );
}

export default SinglePage;
