import React, { useState, useEffect } from "react";
import "./wrapper.scss";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import {
  axios,
  dot,
  layout,
  opensource,
  pack,
  Packages,
  Project,
  repo,
  Repos,
  Sidebar,
  SinglePage,
  star,
  Stars,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { data as dat } from "../../redux/generalDataSlice";

const Wrapper = ({ data }) => {
  const { datas } = useSelector((state) => state.generalData);
  const [logo, setLogo] = useState(false);
  const [navMenu, setNavMenu] = useState(false);
  // const [starCount, setStarCount] = useState(0);
  // const { name } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/Tohirjon-Odilov/repos`)
      .then((response) => {
        dispatch(dat(response.data));
      })
      .catch((err) => {
        throw Error(err);
      });
  }, [dispatch]);

  const handleLogo = () => {
    if (window.scrollY >= 410) {
      return setLogo(
        <>
          <img src={data.avatar_url} alt="avatar" width={30} height={30} />
          <p>Tohirjon-Odilov</p>
        </>
      );
    } else {
      return setLogo(null);
    }
  };

  window.addEventListener("scroll", handleLogo);
  const handleNavMenu = () => {
    setNavMenu((prev) => !prev);
  };

  return (
    <div className="steam">
      {/* navbar starts */}
      <nav className="navbar">
        <div className="container navbar__wrapper">
          <div className="navbar__name">{logo}</div>
          <ul>
            <li className="openSource">
              <NavLink to={`/`}>
                <img src={opensource} alt="opened book" />
                <p>Overview</p>
              </NavLink>
            </li>
            <li className="repository">
              <NavLink to={`repos`}>
                <img src={repo} alt="Books" />
                <p>Repositories</p>
                <span>{data.public_repos || 0}</span>
              </NavLink>
            </li>
            <li className="project">
              <NavLink to={`project`}>
                <img src={layout} alt="project" />
                <p>Project</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={`packages`}>
                <img src={pack} alt="packages" />
                <p>Packages</p>
              </NavLink>
            </li>
            <li className="star flex">
              <NavLink to={`starred`}>
                <img src={star} alt="star" />
                <p>Stars</p>
                <span>{datas.length}</span>
              </NavLink>
            </li>
            <li className="dot" onClick={handleNavMenu}>
              <img src={dot} alt="dot" width={24} height={24} />
            </li>
          </ul>
          {/* small modal */}
          <div className={navMenu ? "innerLink__wrapper" : "hide"}>
            <div className="link">
              <Link to={"starred"}>Starts</Link>
            </div>
            <div className="link">
              <Link to={"packages"}>Packages</Link>
            </div>
            <div className="link">
              <Link to={"project"}>Project</Link>
            </div>
          </div>
        </div>
      </nav>
      {/* navbar ends */}
      <div className="container page-wrapper">
        <div className="sidebar__wrapper">
          <Sidebar data={data} />
          <nav className="navbar navbar-mobile">
            <div className="navbar__wrapper">
              <ul>
                <li className="openSource">
                  <NavLink to={`/`}>
                    <img src={opensource} alt="" />
                    <p>Overview</p>
                  </NavLink>
                </li>
                <li className="repository">
                  <NavLink to={"repos"}>
                    <img src={repo} alt="" />
                    <p>Repositories</p>
                    <span>{data.public_repos}</span>
                  </NavLink>
                </li>
                <li className="project">
                  <NavLink to={"project"}>
                    <img src={layout} alt="" />
                    <p>Project</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"packages"}>
                    <img src={pack} alt="" />
                    <p>Packages</p>
                  </NavLink>
                </li>
                <li className="star flex">
                  <NavLink to={"starred"}>
                    <img src={star} alt="" />
                    <p>Stars</p>
                    <span>{datas.length}</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <Routes>
          <Route path={`/`} element={<SinglePage />} />
          <Route path={`/repos`} element={<Repos />} />
          <Route path={`/project`} element={<Project />} />
          <Route path={`/project`} element={<Packages />} />
          <Route path={`/project`} element={<Stars />} />
        </Routes>
      </div>
    </div>
  );
};

export default Wrapper;
