import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  githubLogo,
  bell,
  plus,
  down,
  menu,
  signOut,
} from "../../utils/constants";
import "./Navbar.scss";

const Index = ({ data }) => {
  const [innerText, setInnerText] = useState("Pull requests");
  const [toggle, setToggle] = useState(false);

  const text = () => {
    // console.log(window.innerWidth);
    if (window.innerWidth <= 1000 && window.innerWidth >= 768) {
      setInnerText("Pulls");
    } else {
      setInnerText("Pull request");
    }
    if (window.innerWidth <= 1013) {
    }
  };
  window.addEventListener("resize", text);

  const menuBar = () => {
    setToggle((prev) => !prev);
  };

  return (
    <header className="site-header">
      <div className="header">
        <div className="header-base">
          <NavLink>
            <img src={githubLogo} alt="Github logotip" width={40} height={40} />
          </NavLink>
          <img src={menu} alt="menu" className="menu" onClick={menuBar} />
          <ul className={toggle ? "menuOn" : ""}>
            <li className="input-wrapper">
              <input type="text" placeholder="Search or jump to..." />
              <span>/</span>
            </li>
            <li className="text">
              <NavLink to={"/"}> Dashboard</NavLink>
            </li>
            <li>
              <NavLink to={"/"}>{innerText}</NavLink>
            </li>
            <li>
              <NavLink to={"/"}>Issues</NavLink>
            </li>
            <li>
              <NavLink to={"/"}>Codespaces</NavLink>
            </li>
            <li>
              <NavLink to={"/"}>Marketplace</NavLink>
            </li>
            <li>
              <NavLink to={"/"}>Explore</NavLink>
            </li>
            <li className="text">
              <NavLink to={"/"}> Sponsors</NavLink>
            </li>
            <li className="text">
              <NavLink to={"/"}> Settings</NavLink>
            </li>
            <li className="text">
              <img src={data.avatar_url} alt="Avatar" />
              <NavLink to={"/"} className="text__link">
                        {"name"}
              </NavLink>
            </li>
            <li className="text">
              <img className="signOut-logo" src={signOut} alt="sign out" />
              <NavLink to={"/"}>         Sign out</NavLink>
            </li>
          </ul>
        </div>
        <div className="header-icon">
          <img src={bell} alt="Bell" />
          <div>
            <img src={plus} alt="Plus" />
            <img src={down} alt="down" />
          </div>
          <div>
            <img
              className="avatar"
              src={data.avatar_url}
              alt="avatar"
              width={23}
              height={23}
            />
            <img src={down} alt="down" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Index;
