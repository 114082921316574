import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import "./assets/styles/style.scss";
import { axios, Footer, Navbar, Wrapper } from "./utils/constants";
// import { useMemo } from "react";
const App = () => {
  const [data, setData] = useState([]);
  // console.log("overview")
  // const { name } = useParams();
  useEffect(() => {
    axios
      .get(`/Tohirjon-Odilov`) // Tohirjon-Odilov
      .then((response) => setData(response.data))
      .catch((err) => {
        throw Error(err);
      });
  }, []);
  // const navigate = useNavigate();
  // navigate(`/${data.login}`)
  // (() => {
  // name === undefined && navigate("Tohirjon-Odilov");
  // })();
  // console.log(name);
  return (
    <>
      <Navbar data={data} />
      <Wrapper data={data} />
      {/* <Routes> */}
      {/* <Route path={`/:name`} element={<Wrapper data={data} />}> */}
      {/* <Route path={`/:name`} element={<SinglePage />} />
        <Route path={`/:name/repos`} element={<h1>Salom</h1>} /> */}
      {/* <Route path={`project`} element={<Repos />} /> */}
      {/* <Route path={`packages`} element={<Repos />} /> */}
      {/* <Route path={`starred`} element={<Repos />} /> */}
      {/* </Route> */}
      {/* </Routes> */}
      <Footer />
    </>
  );
};

export default App;
