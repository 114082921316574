import { createSlice } from "@reduxjs/toolkit";

const generalDataSlice = createSlice({
  name: "generalDataSlice",
  initialState: {
    datas: [],
    // data_stars: 0
  },
  reducers: {
    data: (state, action) => {
      state.datas = action.payload;
    },
    // set_data_star: (state, action) => {
      // state.data_stars = action.payload;
    // },
  },
});

export const { data } = generalDataSlice.actions;
export default generalDataSlice.reducer;
